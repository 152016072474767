.logoSize {
  height: 100px;
  width: auto;
}

.logoText {
  font-weight: 600;
  font-size: 1.5rem;
  padding-top: 50px;
  margin-left: -10px;
  color: white
}

@media screen and (max-width: 480px) {
  .logoSize {
    height: 70px;
    width: auto;
  }
  .logoText {
    font-weight: 600;
    font-size: 1rem;
    padding-top: 40px;
    margin-left: -10px;
    color: white;
  }
}
