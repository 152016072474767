body{
    background: url('../images/bg_image.png') !important;
}
.filterTypePage{
    display: flex;
    padding-bottom: 175px;
}
p{
    font-family: "Poppins", sans-serif !important;
    text-align: center;
    font-weight: 500;
    font-size: 1.5rem;
}
span{
    font-weight:'600' !important;
    vertical-align:'bottom';
}

.tagLine{
    font-size: 2rem;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    text-align: center;
    margin: 4% 0% 2% 0%;
}

.tagLine span{
    background-color: none;
    color: #41f7cb;
    padding-left: 1%;
    padding-right: 1%;
    font-weight: 600;
}

.predictorBtn{
    background-color: #41f7cb;
    color: black;
    border: none;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
}

.predictorPara{
    color: #000;
    font-size: 1.5rem;
    font-weight: 600;
}

.predictor{
    background-color: white;
    box-shadow: 0px 0px 10px 5px rgba(236, 207, 207, 0.5);
    margin: 2% 0%;
    padding:1% 1%;
    border-radius: 15px;
}

#footer {
    bottom: 0;
    position: fixed;
    height: 110px;
    margin-top: 4%;
}

#footer .row{
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    padding: 25px 20px 15px 25px;
}

#footer .row h4{
    font-size: 2rem;
    font-weight: 600;
}
.footer-content {
    color: #0e194d;
    font-weight: 600;
    text-align: center;
}

.footerBtn{
    background-color: #000;
    color: white;
    font-weight: 700;
    font-size: 2rem;
    border-radius: 10px;
    border: none;
    padding: 10px 15px;
}

@media screen and (max-width: 480px) {

    .filterTypePage{
        display: flex;
        padding-bottom: 267px;
    }
    /* footer media query */
    #footer {
        bottom: 0;
        position: fixed;
        height: 220px;
        margin-top: 4%;
    }

    #footer .row h4{
        font-size: 1rem;
        font-weight: 700;
    }
    .footer-content {
        color: #0e194d;
        font-weight: 500;
        text-align: center;
        font-size: 1rem;
    }
    .footerBtn{
        background-color: #000;
        color: white;
        font-weight: 600;
        font-size: 1.5rem;
        border-radius: 10px;
        border: none;
        padding: 6px 10px;
        margin-top: 5%;
    }
    
    .tagLine{
        font-size: 1.2rem;
        color: white;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        text-align: center;
        margin: 5% 0% 4% 0%;
    }
    
    .tagLine span{
        background-color: transparent;
        padding-left: 1%;
        /* color : #080a89; */
        color: #41f7cb;
        padding-right: 1%;
        font-weight: 700;
        font-size: 1.6rem;
        display: block;
    }

    .predictor{
        background-color: white;
        box-shadow: none;
        margin: 2% 0%;
        padding:1% 1%;
        border-radius: 15px;
    }

    .predictorBtn{
        background-color: #41f7cb;
        color: black;
        border: none;
        font-size: 0.7rem;
        font-weight: 600;
        border-radius: 5px;
        padding: 5px 10px 5px 10px;
    }
    
    .predictorPara{
        color: #000;
        font-size: 1rem;
        font-weight: 600;
        text-align: center;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1000px) and (orientation: portrait) {
    .filterTypePage{
        display: flex;
        padding-bottom: 267px;
    }
    /* footer media query */
    #footer {
        bottom: 0;
        position: fixed;
        height: 167px;
        margin-top: 4%;
    }

    #footer .row h4{
        font-size: 1rem;
        font-weight: 700;
    }
    .footer-content {
        color: #0e194d;
        font-weight: 500;
        text-align: center;
        font-size: 1rem;
    }
    .footerBtn{
        background-color: #000;
        color: white;
        font-weight: 600;
        font-size: 1.5rem;
        border-radius: 10px;
        border: none;
        padding: 6px 10px;
        margin-top: 5%;
    }
    
    .tagLine{
        font-size: 1.2rem;
        color: white;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        text-align: center;
        margin: 5% 0% 4% 0%;
    }
    
    .tagLine span{
        background-color: transparent;
        padding-left: 1%;
        /* color : #080a89; */
        color: #41f7cb;
        padding-right: 1%;
        font-weight: 700;
        font-size: 1.6rem;
        display: block;
    }

    .predictor{
        background-color: white;
        box-shadow: none;
        margin: 2% 0%;
        padding:1% 1%;
        border-radius: 15px;
    }

    .predictorBtn{
        background-color: #41f7cb;
        color: black;
        border: none;
        font-size: 0.7rem;
        font-weight: 600;
        border-radius: 5px;
        padding: 5px 10px 5px 10px;
    }
    .predictorPara{
        color: #000;
        font-size: 1rem;
        font-weight: 600;
        text-align: center;
    }
}