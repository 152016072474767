.tableListClass{
    margin-top: 5%;
}

.ant-pagination-item {
    /* color: white !important;
    border: 2px solid aquamarine !important; */
    background-color: #fff !important;
    font-weight: 600;
    margin-right: 5px !important;
}

.ant-pagination-item-active {
    color: white !important;
    background-color: transparent !important;
    /* border: 2px solid black !important; */
}

@media screen and (max-width: 480px) {
    .tableListClass{
        margin-top: 10%;
    }   
}