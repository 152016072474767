h3 {
  text-align: center;
  font-size: 2rem;
  margin-top: 3%;
}

h3 span {
  background-color: #00d4a1;
  padding: 4px 10px;
  font-weight: 600;
}

.detailsLine {
  color: white;
  margin: 2% 0%;
}

.formDiv {
  background-color: transparent;
  /* box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.5); */
  padding: 1% 0%;
  /* background-color: #0f1e35; */
  align-items: center;
  text-align: center;
  border-radius: 20px;
}

.formDiv input {
  border-radius: 0px;
}

.actionBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.predict {
  width: 60% !important;
}

.back {
  width: 60% !important;
}

.comingSoonImage{
    margin-top: 5%;
    height: 370px;
    width: auto;
    border-radius: 50%;
}

@media screen and (max-width: 480px) {
  h3 {
    text-align: center;
    font-size: 2rem;
    margin-top: 7%;
    background-color: none;
  }

  h3 span {
    background-color: transparent;
    color: #00d4a1;
    padding: 4px 10px;
    font-weight: 600;
  }

  .detailsLine {
    font-size: 1.3rem;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .predict {
    width: 100% !important;
  }

  .back {
    width: 100% !important;
  }

  .input-group {
    margin-bottom: 10% !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Your CSS styles here */
  h3 {
    text-align: center;
    font-size: 2rem;
    margin-top: 7%;
    background-color: none;
  }

  h3 span {
    background-color: transparent;
    color: #00d4a1;
    padding: 4px 10px;
    font-weight: 600;
  }

  .detailsLine {
    font-size: 1.3rem;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .predict {
    width: 100% !important;
  }

  .back {
    width: 100% !important;
  }

  .input-group {
    margin-bottom: 10% !important;
  }
}
