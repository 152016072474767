@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Poppins:wght@400;500;600;700;800;900&display=swap");
body {
  margin: 0;
  font-family: "Poppins", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("./components/images/bg_image.png");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.heroDivContent h2 {
  color: white;
  font-size: 4rem;
  font-weight: 700;
  word-wrap: break-word;
}

.heroDivContent span {
  color: #41f7cb;
}
p {
  color: white;
  font-size: 2rem;
}

.arrowImage {
  margin-left: 50%;
}

#img {
  height: 100px;
  width: auto;
}

h3 {
  color: white;
  font-family: "Poppins", sans-serif;
}
.login-div {
  background-color: transparent;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.5);
  padding: 10% 0%;
  background-color: #0f1e35;
  align-items: center;
  text-align: center;
  border-radius: 20px;
}

.input-group {
  padding: 0 10%;
}

.inputFieldHeading {
  text-align: start;
  color: white;
  padding: 0 10%;
  font-weight: 400;
}
.input-group input {
  border-radius: 25px;
}

.tags {
  margin-left: "50%";
  word-wrap: "break-word";
}

.loginBtn {
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 25px;
  padding: 0% 5%;
  margin-top: 5%;
  font-family: "Poppins", sans-serif;
  width: 100%;
  border: none;
  color: white;
  background-image: linear-gradient(to right, #51a2bf, #02ced6, #41f7cb);
}

.card_dull_login_btn{
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 25px;
  padding: 0% 5%;
  font-family: "Poppins", sans-serif;
  width: 100%;
  border: none;
  color: white;
  opacity: 0.6;
  pointer-events: none;
  background-image: linear-gradient(to right, #51a2bf, #02ced6, #41f7cb);
}

p {
  font-family: "Dancing Script", cursive;
  /* "Poppins", sans-serif; */
}

.helpTab {
  margin-top: 10%;
  color: white;
}

.dangerText{
  color: red;
}

@media screen and (max-width: 480px) {
  .heroDivContent h2 {
    color: white;
    font-size: 2rem;
    font-weight: 600;
    word-wrap: break-word;
  }

  .arrowImage {
    margin-left: 30%;
  }

  .arrowImage #img{
    height: 40px;
  }

  .tags {
    margin-left: 0%;
    margin-top: 5%;
    word-wrap: normal;
  }
  .login-div {
    background-color: transparent;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.5);
    padding: 5% 0%;
    background-color: #0f1e35;
    align-items: center;
    text-align: center;
    border-radius: 20px;
  }

  .loginInnerContainer{
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .login-div h3{
    font-size: 1.5rem;
  }

  .input-group {
    padding: 0 5%;
    margin-bottom: 2% !important;
  }
  
  .inputFieldHeading {
    text-align: start;
    color: white;
    padding: 0 5%;
    font-weight: 400;
  }

  .loginBtn{
    margin-top: 2%;
  }
}
